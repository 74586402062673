import { useEffect, useState } from 'react'
import { message, Progress, Skeleton, Tabs } from 'antd'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import {
	AddCircleOutline,
	ChevronLeftOutlined,
	Expand,
	ExpandMore,
	NorthEastOutlined,
	SouthEastOutlined
} from '@mui/icons-material'
import { Icon, IconButton } from '@mui/material'
import Status from '../components/views/Projects/ProjectsDetails/Status'
import searchIcon from '../assets/images/search-icon.svg'
import filterIcon from '../assets/images/filter-icon.svg'
import ButtonComponent from '../components/shared/ButtonComponent'
import addCircleBlue from '../assets/images/add-circle-blue.svg'
import DropdownFilter from '../components/shared/DropdownFilter'
import optionsIcon from '../assets/images/options-icon.svg'
import bellIcon from '../assets/images/bell-icon.svg'
import editIcon from '../assets/images/edit-icon.svg'
import deleteFilledIcon from '../assets/images/delete-filled-icon.svg'
import dayjs from 'dayjs'
import { handleProjectStatus } from '../API/projectsAPI'
import { getProject } from '../API/projectsAPI'
import Tasks from '../components/views/Projects/ProjectsDetails/Tasks'
import Members from '../components/views/Projects/ProjectsDetails/Members'
import dropdownArrow from '../assets/images/dropdown-arrow.svg'
import pinIcon from '../assets/images/pin-icon.svg'
import callLogIcon from '../assets/images/call-log-icon.svg'
import recieptIcon from '../assets/images/receipt-icon.svg'
import personIcon from '../assets/images/person-icon.svg'
import tickIcon from '../assets/images/tick-icon.svg'
import CreateNewProjectTask from '../../src/components/shared/Modals/CreateNewProjectTask'
import Sprints from '../components/views/Projects/ProjectsDetails/Sprints'
import CreateNewSprint from '../components/shared/Modals/CreateNewSprint'

const ProjectDetails = () => {
	const [messageApi, contextHolder] = message.useMessage()

	const location = useLocation()
	const navigate = useNavigate()

	// const { state } = location
	// const { projectId } = state

	const params = useParams()
	const { id } = params

	const [data, setData] = useState({})

	const [activeKey, setActiveKey] = useState('1')

	const [addNewTask, setAddNewTask] = useState(false)
	const [newTaskAdded, setNewTaskAdded] = useState(false)
	const [addNewSprint, setAddNewSprint] = useState(false)
	const [newSprintAdded, setNewSprintAdded] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [editData, setEditData] = useState({})

	const getProjectData = async () => {
		setIsLoading(true)
		try {
			const res = await getProject(id)
			setData({
				projectDetail: {
					projectName: res?.data?.name,
					imageUrl: res?.data?.imageUrl || ''
				},
				projectLeadDetail: {
					projectLead: res?.data?.projectLead?.name
						? res?.data?.projectLead?.name
						: res?.data?.leadName,
					projectLeadAvatarUrl: res?.data?.projectLead?.imageUrl
				},
				profitOrLoss: res?.data?.profitOrLoss,
				clientName: res?.data?.clientName,
				activeEmployees: res?.data?.members || [],
				contactLead: 'Contact Project Lead',
				projectId: res?.data?.id,
				startDate: res?.data?.startDate,
				status: res?.data?.status,
				dueDate: res?.data?.dueDate,
				projectDescription: res?.data?.description || '',
				projectProgress: res?.data?.projectProgress,
				members: res?.data?.members || []
			})

			setIsLoading(false)
		} catch (error) {
			debugger
			messageApi.error('Unable to fetch project data.')
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getProjectData()
	}, [])

	const newTask = () => {
		setAddNewTask(true)
	}

	const newSprint = () => {
		setAddNewSprint(true)
	}

	const items = [
		// {
		// 	key: '1',
		// 	label: 'Profit & Loss'
		// },
		{
			key: '1',
			// label: 'Issues & Backlogs (20)',
			label: 'Tasks',
			children: (
				<Tasks
					projectId={data?.projectId || id}
					newProjectTaskAdded={newTaskAdded}
					getData={getProjectData}
					setNewTaskAdded={setNewTaskAdded}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					activeKey={activeKey}
				/>
			)
		},
		{
			key: '2',
			label: `Members`,
			children: <Members membersData={data?.members} activeKey={activeKey} />
		},
		{
			key: '3',
			label: 'Sprint',
			children: (
				<Sprints
					projectId={data?.projectId || id}
					newProjectTaskAdded={newTaskAdded}
					getData={getProjectData}
					projectName={data?.projectDetail?.projectName ?? ''}
					newSprint={newSprint}
					setEditData={setEditData}
					addNewSprint={addNewSprint}
					activeKey={activeKey}
				/>
			)
		}

		// {
		// 	key: '4',
		// 	label: 'Invoices(300)'
		// },
		// {
		// 	key: '5',
		// 	label: 'Proposal(300)'
		// },
		// {
		// 	key: '6',
		// 	label: 'Files(300)'
		// },
		// {
		// 	key: '7',
		// 	label: 'Activity(300)'
		// }
	]

	const options = [
		{
			key: '1',
			label: (
				<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					<img src={deleteFilledIcon} alt="icon" />
					<span className="font-roboto fs-12 fw-semibold ms-2">Delete</span>
				</div>
			)
		},
		{
			key: '2',
			label: (
				<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					<img src={editIcon} alt="icon" />
					<span className="font-roboto fs-12 fw-semibold ms-2">Edit</span>
				</div>
			)
		}
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2">
		// 			<img src={bellIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Reminder
		// 			</span>
		// 		</div>
		// 	)
		// }
	]

	const projectOperations = [
		{
			key: '1',
			label: (
				<div className="p-2" onClick={newTask}>
					<AddCircleOutline />
					<span className="font-roboto fs-12 fw-semibold ms-2">Add Task</span>
				</div>
			)
		},
		// {
		// 	key: '2',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<AddCircleOutline />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Member</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<AddCircleOutline />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Invoice
		// 			</span>
		// 		</div>
		// 	)
		// },
		{
			key: '4',
			label: (
				<div className="p-2" onClick={newSprint}>
					<AddCircleOutline />
					<span className="font-roboto fs-12 fw-semibold ms-2">Add Sprint</span>
				</div>
			)
		}
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={recieptIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Proposal
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={callLogIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Call Logs
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={pinIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Files</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={bellIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Reminder
		// 			</span>
		// 		</div>
		// 	)
		// }
	]

	const statusOptions = [
		{
			value: 'On Hold',
			label: 'On hold'
		},
		{
			value: 'Active',
			label: 'Active'
		},
		{
			value: 'Completed',
			label: 'Completed'
		},
		{
			value: 'Upcoming',
			label: 'Upcoming'
		}
	]

	const getStyles = (value) => {
		switch (value) {
			case 'On Hold':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF'
				}
			case 'Active':
				return {
					borderColor: '1px solid #DEC3F7',
					backgroundColor: '#F9F0FF',
					color: '#722ED1'
				}
			case 'Completed':
				return {
					borderColor: '1px solid #FFEBC9',
					backgroundColor: '#FFF7E6',
					color: '#E19A0C'
				}
			case 'Upcoming':
				return {
					borderColor: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A'
				}
			default:
				return {}
		}
	}

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const handleStatusChange = async (record, status) => {
		try {
			const options = { id: record.projectId, status: status }
			const res = await handleProjectStatus(options)
		} catch (error) {
			console.log('error from put is ', error)
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate) // Replace '2023-08-28' with your date

		const formattedDate = date.format('MMMM YYYY') // 'D' for day, 'MMMM' for full month name, 'YYYY' for year

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between row">
					<div className="col-12 col-md-5">
						<div
							className="d-flex align-items-start mb-2"
							style={{
								marginLeft: '-10px'
							}}
						>
							<IconButton
								className=""
								onClick={() => {
									navigate('/dashboard/projects')
								}}
							>
								<ChevronLeftOutlined
									sx={{
										color: '#323232'
									}}
								/>
							</IconButton>
							<div className="d-flex flex-column">
								<h1
									className="fs-3 mb-0 dashboard-primary-header d-flex mt-1"
									onClick={() => {
										if (!isLoading) {
											navigate('/dashboard/projects')
										}
									}}
									style={isLoading ? null : { cursor: 'pointer' }}
								>
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '10rem',
												height: '2rem'
											}}
										/>
									) : (
										data?.projectDetail?.projectName
									)}
								</h1>

								<p className="font-roboto fs-12 txt-color-primary mb-0">
									{data?.projectDescription}
								</p>
							</div>
						</div>

						<p className="txt-color-primary font-roboto mb-0 ms-4">
							{data?.projectDetail?.projectDescription || ''}
						</p>
					</div>
					<div className="col-12 col-md-7">
						<div className="d-flex align-items-center w-100 justify-content-end h-100">
							{/* <Link className="font-roboto txt-color-blue-1 fs-14 text-decoration-none fw-500">
								Manage Issue
							</Link> */}
							{/* <ButtonComponent
								text={
									<div className="d-flex justify-content-center align-items-center">
										<span className="font-roboto me-2">Add</span>
										<DropdownFilter
											title=""
											menuItems={projectOperations}
											onSelect={() => {
												console.log('option selected')
											}}
											icon={<img src={dropdownArrow} alt="icon" />}
											className="ms-2"
										/>
									</div>
								}
								variant="light"
								icon={<img src={addCircleBlue} alt="blue-add-circle" />}
							/> */}

							<div className="d-flex justify-content-center align-items-center">
								<DropdownFilter
									title={
										<span
											className="fs-14 font-roboto fw-semibold d-flex align-items-center gap-2"
											style={{
												color: '#204464'
											}}
										>
											<AddCircleOutline
												sx={{
													color: '#204464',
													fontSize: '1.25rem'
												}}
											/>{' '}
											Add
										</span>
									}
									menuItems={projectOperations}
									icon={
										<ExpandMore
											sx={{
												color: '#204464',
												fontSize: '1.25rem',
												marginLeft: '8px'
											}}
										/>
									}
									extraclass="add-operation"
								></DropdownFilter>
							</div>

							{/* <div
								style={{ width: '25px', height: '25px', borderRadius: '50%' }}
								className="d-flex justify-content-center align-items-center ms-3"
							>
								<DropdownFilter
									title=""
									menuItems={options}
									onSelect={() => {
										console.log('option selected')
									}}
									icon={<img src={optionsIcon} alt="icon" />}
								/>
							</div> */}
						</div>
					</div>
				</div>
				<div className="bg-white px-4 py-3 mb-4 mt-4">
					<div className="row p-0 m-0">
						<div className="col-12 col-md-4">
							<div className="row d-flex align-items-center">
								<div className="col-12 col-md-5">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Project Lead
									</h6>
									<div className="d-flex align-items-center">
										{isLoading ? (
											<Skeleton.Button
												className=""
												style={{
													width: '10rem',
													height: '2rem'
												}}
											/>
										) : data?.projectLeadDetail?.projectLead &&
										  data?.projectLeadDetail?.projectLead !== '' ? (
											<>
												<AvatarGroupComponent
													size={32}
													imageUrl={data?.projectLeadDetail?.imageUrl || ''}
													name={data?.projectLeadDetail?.projectLead || ''}
												/>
												<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
													{data?.projectLeadDetail?.projectLead}
												</p>
											</>
										) : (
											<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
												----
											</p>
										)}
									</div>
								</div>
								<div className="col-12 col-md-5">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Client Name
									</h6>
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '10rem',
												height: '2rem'
											}}
										/>
									) : data?.clientName && data?.clientName !== '' ? (
										<>
											<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
												{data?.clientName}
											</p>
										</>
									) : (
										<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
											----
										</p>
									)}
								</div>
							</div>
						</div>
						<div className="col-12 col-md-8">
							<div className="row d-flex align-items-center">
								<div className="col-12 col-md-3">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Start date
									</h6>
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '8rem',
												height: '2rem'
											}}
										/>
									) : data?.startDate && data?.startDate !== '' ? (
										<>
											<span className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
												{formatDate(data?.startDate)}
											</span>
										</>
									) : (
										<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
											----
										</p>
									)}
								</div>
								<div className="col-12 col-md-3">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Due date
									</h6>
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '8rem',
												height: '2rem'
											}}
										/>
									) : data?.dueDate && data?.dueDate !== '' ? (
										<>
											<span className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
												{formatDate(data?.dueDate)}
											</span>
										</>
									) : (
										<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
											----
										</p>
									)}
								</div>
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Project Status
									</h6>
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '8rem',
												height: '2rem'
											}}
										/>
									) : data?.status && data?.status !== '' ? (
										<>
											<div onClick={(e) => e.stopPropagation()}>
												<Status
													value={data?.status}
													statusOptions={statusOptions}
													select={(newStatus) => {
														handleStatusChange(data, newStatus)
													}}
													getStyles={getStyles}
												/>
											</div>
										</>
									) : (
										<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
											----
										</p>
									)}
								</div>
								<div className="col-12 col-md-4">
									<div className="d-flex">
										<div className="d-flex flex-column">
											<h6 className="font-roboto txt-color-secondary fs-12">
												Project Progress
											</h6>

											{isLoading ? (
												<Skeleton.Button
													className=""
													style={{
														width: '8rem',
														height: '2rem'
													}}
												/>
											) : (
												<>
													<span className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
														{data?.projectProgress} % completed
													</span>
												</>
											)}
										</div>
										{isLoading ? null : (
											<Progress
												type="circle"
												percent={data?.projectProgress}
												size={40}
												showInfo={false}
												strokeWidth={20}
												className="ms-3"
												strokeColor="#4796DF"
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div
					style={{
						marginBottom: '-35px',
						float: 'right',
						zIndex: '1',
						position: 'relative'
					}}
				>
					<button className="ms-4 operations-on-table">
						<img src={searchIcon} alt="search-icon" className="px-1" />
						<span className="ms-1 fs-14 font-roboto">Search</span>
					</button>
					<button className="ms-4 operations-on-table">
						<img src={filterIcon} alt="filter-icon" className="px-1" />
						<span className="ms-1 fs-14 font-roboto">Filter</span>
					</button>
				</div> */}
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					className="dashboard-tabs"
					activeKey={activeKey}
					onChange={(key) => {
						setActiveKey(key)
					}}
				/>
				{addNewTask && (
					<CreateNewProjectTask
						open={addNewTask}
						onClose={() => {
							setAddNewTask(false)
						}}
						setOpen={setAddNewTask}
						projectId={data?.projectId}
						projectName={data?.projectDetail?.projectName}
						setNewTaskAdded={setNewTaskAdded}
						getData={getProjectData}
					/>
				)}
				{addNewSprint && (
					<CreateNewSprint
						open={addNewSprint}
						onClose={() => setAddNewSprint(false)}
						setOpen={setAddNewSprint}
						projectId={data?.projectId}
						projectName={data?.projectDetail?.projectName}
						setNewSprintAdded={setNewSprintAdded}
						editData={editData}
						setEditData={setEditData}
						getData={getProjectData}
					/>
				)}
			</div>
		</>
	)
}

export default ProjectDetails
