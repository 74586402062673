import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import FormComponent from '../../shared/forms'
import ButtonComponent from '../../shared/ButtonComponent'
import {
	createCallLog,
	getDomains,
	updateCallLog
} from '../../../API/callLogsAPI'
import dayjs from 'dayjs'
import { getAllClients } from '../../../API/clientAPI'
import { getAllProjects } from '../../../API/projectsAPI'
import useMessage from 'antd/es/message/useMessage'

const CallLogForm = ({ isUpdate, updateData, getData, setIsModalOpen }) => {
	const [message, contextHolder] = useMessage()
	const [domainOptions, setDomainOptions] = React.useState([])
	const [clientOptions, setClientOptions] = React.useState([])
	const [projectOptions, setProjectOptins] = React.useState([])

	const getSecondsFromTimeString = (timeString) => {
		let timeParts = timeString.split(':').map((str) => parseInt(str))
		if (timeParts.length === 2) {
			let minutes = timeParts[0] * 60
			let seconds = timeParts[1]
			return minutes + seconds
		} else if (timeParts.length === 3) {
			let hours = timeParts[2] * 3600
			let minutes = timeParts[1] * 60
			let seconds = timeParts[0]
			return hours + minutes + seconds
		} else {
			return 0
		}
	}

	const convertSecondsToTimeString = (seconds) => {
		let sec = parseInt(seconds)
		const hours = Math.floor(sec / 3600)
		const minutes = Math.floor((sec % 3600) / 60)
		const remainingSeconds = sec % 60
		return `${
			hours > 0
				? hours.toString().length < 2
					? `0${hours}`
					: `${hours}`
				: `00`
		}:${
			minutes > 0
				? minutes.toString().length < 2
					? `0${minutes}`
					: `${minutes}`
				: `00`
		}:${
			remainingSeconds > 0
				? remainingSeconds.toString().length < 2
					? `0${remainingSeconds}`
					: `${remainingSeconds}`
				: `00`
		}`
	}

	const initialValues = {
		type: updateData?.type || '',
		assignedTo: updateData?.assignedTo || '',
		//duration: updateData?.duration && updateData?.duration !== null ? makeDurationFromSeconds(updateData?.duration) : dayjs(),
		duration:
			updateData?.duration && updateData?.duration !== null
				? convertSecondsToTimeString(updateData?.duration)
				: '',
		phoneNumber: updateData?.phoneNumber || '',
		purpose: updateData?.purpose || '',
		description: updateData?.description || '',
		timeOfCall:
			updateData?.timeOfCall && updateData?.timeOfCall !== null
				? dayjs(updateData?.timeOfCall)
				: dayjs(new Date()),
		extension: updateData?.extension || '',
		projectId: updateData?.project?.id || '',
		callerName: updateData?.callerName || '',
		clientId: updateData?.client?.id || '',
		callDate:
			updateData?.callDate && updateData?.callDate !== null
				? dayjs(updateData?.callDate)
				: dayjs(new Date()),
		domain: updateData?.domain || ''
	}

	const validationSchema = Yup.object({
		clientId: Yup.string().required('Required'),
		projectId: Yup.string().required('Required'),
		callerName: Yup.string().required('Required'),
		assignedTo: Yup.string().required('Required'),
		phoneNumber: Yup.string().required('Required'),
		duration: Yup.string()
			.matches(
				/^([0-9]+:)?[0-5][0-9]:[0-5][0-9]$/,
				'Invalid duration format. Use HH:mm:ss format.'
			)
			.required('Required'),
		extension: Yup.string().required('Required'),
		type: Yup.string().required('Required'),
		purpose: Yup.string().required('Required'),
		callDate: Yup.string().required('Required'),
		domain: Yup.string().required('Required')
	})

	const getDomainsData = async () => {
		try {
			const res = await getDomains()
			const { data = [] } = res
			if (data.length !== 0) {
				setDomainOptions(
					data &&
						data.map((domainObj) => {
							let domainName = domainObj?.domain.split('.')

							return {
								value: domainObj?.domain,
								label: domainName[0]
							}
						})
				)
			}
		} catch (error) {}
	}

	const getClients = async () => {
		try {
			const res = await getAllClients()
			const { data = [] } = res
			if (data.length !== 0) {
				setClientOptions(
					data &&
						data.map((clientObj) => {
							return {
								value: clientObj?.id,
								label: clientObj?.name
							}
						})
				)
			}
		} catch (error) {}
	}

	const getProjects = async () => {
		try {
			const res = await getAllProjects()
			const { data = [] } = res
			if (data.length !== 0) {
				setProjectOptins(
					data &&
						data.map((projectObj) => {
							return {
								value: projectObj?.id,
								label: projectObj?.name
							}
						})
				)
			}
		} catch (error) {}
	}

	useEffect(() => {
		getDomainsData()
		getClients()
		getProjects()
	}, [])

	const callTypeOptions = [
		{
			key: 'Incoming call',
			value: 'Incoming call'
		},
		{
			key: 'Missed call',
			value: 'Missed call'
		},
		{
			key: 'Outgoing call',
			value: 'Outgoing call'
		},
		{
			key: 'Active call',
			value: 'Active call'
		},
		{
			key: 'Voice mail',
			value: 'Voice mail'
		}
	]

	const onSubmit = async (values) => {
		let body = { ...values }
		body.timeOfCall = dayjs(values.timeOfCall).format('YYYY-MM-DD hh:mm:ss')
		body.duration = getSecondsFromTimeString(values?.duration)
		body.callDate = new Date(values?.callDate)

		if (updateData) {
			body.id = updateData?.id
		}

		try {
			if (isUpdate) {
				const res = await updateCallLog(body)
				getData()
				setIsModalOpen(false)
				message.success('Call log updated successfully.')
				//resetForm()
			} else {
				const res = await createCallLog(body)
				getData()
				setIsModalOpen(false)
				//resetForm()
				message.success('Call log added successfully.')
			}
		} catch (error) {
			message.error(
				isUpdate ? 'Unable to updated call log.' : 'Unable to add call log.'
			)
		}
	}

	return (
		<>
			{contextHolder}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize={true}
			>
				{({ values, setFieldValue, handleSubmit, errors }) => {
					console.log('errors', errors)
					return (
						<Form>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="clientId"
										label={<>Select client</>}
										required
										placeholder="Select client"
										options={clientOptions}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="projectId"
										label={<>Select project</>}
										required
										placeholder="Select project"
										options={projectOptions}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="input"
										name="callerName"
										label={<>Caller Name</>}
										required
										placeholder="Enter caller Name"
										type="text"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="input"
										name="assignedTo"
										label={<>Recieved by</>}
										required
										placeholder="Enter assignee Name"
										type="text"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="type"
										label={<>Call type</>}
										options={callTypeOptions}
										placeholder="Select call type"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="date-picker"
										name="callDate"
										label={<>Date of call</>}
										placeholder="Enter call date"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="domain"
										label={<>Select Domain</>}
										placeholder="Select domain"
										options={domainOptions}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="time-picker"
										name="timeOfCall"
										label={<>Time of call</>}
										placeholder="Enter call time"
										format={'hh:mm:ss A'}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="duration-picker"
										name="duration"
										label={<>Call Duration</>}
										placeholder="1h 03 m 00 s (01:03:00)"
										value={values?.duration}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="input"
										name="extension"
										label={<>Extension</>}
										required
										placeholder="Enter extension number"
										type="text"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										name="phoneNumber"
										label={<>Phone Number </>}
										control="phone-input"
										type="text"
										country="us"
										onChange={(phoneNumber) =>
											setFieldValue('phoneNumber', phoneNumber)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<FormComponent
										control="input"
										name="purpose"
										label={<>Purpose of call</>}
										required
										placeholder="Enter purpose for call"
										type="text"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<FormComponent
										control="input"
										name="description"
										label={<>Call Description (optional)</>}
										required
										placeholder="Enter description  for call"
										type="text"
									/>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end">
								<ButtonComponent
									type="button"
									text={isUpdate ? 'Edit' : 'Add'}
									variant="dark"
									click={handleSubmit}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default CallLogForm
